<template>
  <section class="l-content">
    <el-card class="l-condition" name="hTable">
      <el-form :inline="true" :model="condition">
        <el-form-item label="所属部门">
          <l-select v-model="condition.departId" :remote="$api.departList" @change="getPageData()"></l-select>
        </el-form-item>
        <el-form-item label="员工类型">
          <l-select v-model="condition.mType" :data="$constant.employeeMTypeList" @change="getPageData()"></l-select>
        </el-form-item>
        <el-form-item label="在职情况">
          <l-select v-model="condition.isQuit" :data="$constant.employeeIsQuitList" @change="getPageData()"></l-select>
        </el-form-item>
        <el-form-item label="公司高管">
          <l-select v-model="condition.isSenior" :data="$constant.employeeIsLeaderList" @change="getPageData()"></l-select>
        </el-form-item>
        <el-form-item label="部门领导">
          <l-select v-model="condition.isLeader" :data="$constant.employeeIsLeaderList" @change="getPageData()"></l-select>
        </el-form-item>
      </el-form>
      <el-form :inline="true" :model="condition">
        <el-form-item label="关键字">
          <el-input v-model="condition.keyword" size="small" placeholder="姓名|电话|职位"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getPageData()">查询</el-button>
        </el-form-item>
        <el-form-item class="fr">
          <l-upload @success="uploadSuccess" url="api/enterprise/employee/import">
            <el-button type="primary" size="small" icon="el-icon-upload">Excel批量导入</el-button>
          </l-upload>
          <el-link class="margin-lr-sm" type="primary" target="_blank" :href="$site.URL_API + 'template/导入-员工批量导入模板.xlsx'">模板下载</el-link>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider></el-divider>
    <el-card v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list">
        <template slot="name" slot-scope="scope">
          <div class="flex align-center">
            <!-- <el-avatar size="small" :src="scope.row.avatar"></el-avatar> -->
            <span class="padding-left-xs" :class="scope.row.employeeType == 10 ? 'text-purple' : ''">{{ scope.row.name }}</span>
          </div>
        </template>
        <template slot="mType" slot-scope="scope">
          <span v-if="scope.row.mType == 'formal'" class="text-purple">正式员工</span>
          <span v-else-if="scope.row.mType == 'external'" class="text-cyan">外雇员工</span>
        </template>
        <template slot="title" slot-scope="scope">
          <el-tag type="success" effect="dark" size="mini" v-if="scope.row.isBoss" class="margin-right-mini">公司老板</el-tag>
          <el-tag type="" effect="dark" size="mini" v-if="scope.row.isSenior" class="margin-right-mini">企业高管</el-tag>
          <el-tag type="" effect="dark" size="mini" v-if="scope.row.isLeader" class="margin-right-mini">部门领导</el-tag>
          <el-tag effect="dark" size="mini" v-if="!scope.row.isBoss && !scope.row.isSenior && !scope.row.isLeader" class="margin-right-mini">普通员工</el-tag>
          <el-tag type="warning" effect="dark" size="mini" v-if="scope.row.isAdmin" class="margin-right-mini">管理员</el-tag>
          <el-tag type="primary" effect="dark" size="mini" v-if="scope.row.title">{{ scope.row.title }}</el-tag>
        </template>
        <template slot="mType22" slot-scope="scope">
          <el-tag type="success" effect="dark" size="mini" v-if="scope.row.mType == 'formal'">正式员工</el-tag>
          <el-tag type="success" effect="dark" size="mini" v-else-if="scope.row.mType == 'external'">外雇员工</el-tag>
        </template>
        <template slot="isQuit" slot-scope="scope">
          <span v-if="!scope.row.isQuit" class="text-purple">在职</span>
          <span v-if="scope.row.isQuit" class="text-red">离职</span>
        </template>
        <template slot="operation" slot-scope="scope">
          <el-dropdown split-button type="primary" size="mini" icon="el-icon-edit" trigger="click" @click="edit(scope.row)" @command="dropdownCommand(scope.row, $event)">
            修改信息
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-if="!scope.row.isQuit" @click="resetPWD(scope.row)" command="resetpwd">重置密码</el-dropdown-item>
              <el-dropdown-item v-if="!scope.row.isQuit" divided command="employee_quit">员工离职</el-dropdown-item>
              <el-dropdown-item v-if="scope.row.isQuit" command="employee_recover">恢复入职</el-dropdown-item>
              <el-dropdown-item v-if="scope.row.isQuit" divided command="employee_delete">删除信息</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData"></l-pager>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import employeeAddoredit from './employee_addoredit'
import employeeImport from './employee_import'

export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'Employee',
  data() {
    return {
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '姓名', slot: 'name' },
          { label: '工号', prop: 'job_number' },
          { label: '手机号', prop: 'mobile' },
          { label: '性别', prop: 'gender' },
          { label: '部门', prop: 'departName' },
          { label: '员工类型', slot: 'mType' },
          { label: '员工标签', slot: 'title' },
          { label: '在职状态', slot: 'isQuit' }
        ],
        operation: {
          width: '220',
          header: { type: 'warning', icon: 'el-icon-plus', label: '新增员工', click: this.add },
          slot: 'operation'
        }
      },
      condition: {
        keyword: '',
        // enterpriseCode: this.userInfo.enterpriseCode,
        departId: '',
        mType: '',
        isQuit: '',
        isLeader: '',
        isSenior: '',
        pageIndex: 1,
        pageSize: 10
      },
      pageData: null
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/employee/list', ['enterpriseCode', this.condition])
    },
    dropdownCommand(item, command) {
      if (command === 'resetpwd') {
        this.resetPWD(item)
      } else if (command === 'employee_quit') {
        this.quitEmployee(item)
      } else if (command === 'employee_recover') {
        this.recoverEmployee(item)
      } else if (command === 'employee_delete') {
        this.deleteEmployee(item)
      }
    },
    resetPWD(item) {
      this.$lgh.msgConfirm('确认重置[' + item.name + ']密码?', async () => {
        var r = await this.$lgh.get('api/enterprise/employee/resetpwd', {
          userId: item.userId
        })
        this.$lgh.notifySuccess('重置成功', item.name + '密码已重置为:' + r)
      })
    },
    quitEmployee(item) {
      this.$lgh.msgConfirm('确认[' + item.name + ']离职，需要选择新员工，代替其审核权限?', async () => {
        this.$site.sel_enterpriseEmployee(item.enterpriseCode, { }).then((user) => {
          this.$lgh.msgConfirm('确认将[' + item.name + ']审核权限，转移给[' + user.name + ']吗？', async () => {
            // var data = Object.assign({}, item)
            // data.transferDesc = '管理员[' + this.userInfo.fullName + ']后台操作建议审核转移'
            // data.transferDepartId = user.departId
            // data.transferDepartName = user.departName
            // data.transferUserId = user.userId
            // data.transferUserName = user.name
            await this.$lgh.get('api/enterprise/employee/quit', {
              employeeId: item.employeeId, verifyId: user.employeeId
            })
            this.$lgh.notifySuccess('离职成功', item.name)
            this.getPageData()
          })
        })
      })
    },
    recoverEmployee(item) {
      this.$lgh.msgConfirm('确认[' + item.name + ']恢复入职?', async () => {
        await this.$lgh.get('api/enterprise/employee/recover', {
          employeeId: item.employeeId
        })
        this.$lgh.notifySuccess('恢复入职成功', item.name)
        this.getPageData()
      })
    },
    deleteEmployee(item) {
      this.$lgh.msgConfirm('确认[' + item.name + ']删除员工关联信息?', async () => {
        await this.$lgh.get('api/enterprise/employee/delete', {
          employeeId: item.employeeId
        })
        this.$lgh.notifySuccess('删除完成', item.name)
        this.getPageData()
      })
    },
    async uploadSuccess(data) {
      data.forEach((a) => {
        a.enterpriseCode = this.userInfo.enterpriseCode
      })
      await this.$lgh.openDialog({
        ins: employeeImport,
        title: '员工批量导入',
        data: {
          dataList: data
        }
      })
      this.getPageData()
    },
    async add() {
      await this.$lgh.openDialog({
        ins: employeeAddoredit,
        title: '新增员工信息',
        data: {
          departList: this.departList,
          roleList: this.roleList,
          dataInfo: {
            enterpriseCode: this.userInfo.enterpriseCode,
            mType: 'formal',
            gender: '男',
            isLeader: false,
            isSenior: false,
            isAdmin: false,
            isBoss: false,
            isQuit: false
          }
        }
      })
      this.$lgh.toastSuccess('新建员工信息成功')

      this.getPageData()
    },
    async edit(item) {
      await this.$lgh.openDialog({
        ins: employeeAddoredit,
        title: '修改员工信息',
        data: {
          departList: this.departList,
          roleList: this.roleList,
          dataInfo: Object.assign({}, item)
        }
      })
      this.$lgh.toastSuccess('修改员工信息成功')
      this.getPageData()
    },
    del(item) {
      this.$lgh.msgConfirm('确认删除员工信息?', async () => {
        await this.$lgh.get('api/enterprise/employee/delete', {
          userId: item.userId
        })
        this.$lgh.notifySuccess('删除成功', item.fullName + '用户信息已删除')
        this.getPageData()
      })
    }
  }
}
</script>
