<template>
  <el-dialog v-if="dataInfo" :title="title" @closed="_closed" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false">
    <el-form ref="dataInfo" class="twice padding" :model="dataInfo" :rules="dataRules" label-width="120px">
      <el-form-item label="手机号" prop="mobile">
        <el-input v-model="dataInfo.mobile" autocomplete="off" :disabled="dataInfo.employeeId" style="width:200px"></el-input>
      </el-form-item>
      <el-form-item label="员工姓名" prop="name">
        <el-input v-model="dataInfo.name" autocomplete="off" style="width:200px"></el-input>
      </el-form-item>
      <el-form-item label="性别">
        <el-radio-group v-model="dataInfo.gender">
          <el-radio-button label="男"></el-radio-button>
          <el-radio-button label="女"></el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="员工类别" prop="mType">
        <l-select v-model="dataInfo.mType" :all="false" :data="$constant.employeeMTypeList" style="width:120px"></l-select>
      </el-form-item>
      <el-form-item label="工号">
        <el-input v-model="dataInfo.job_number" autocomplete="off" style="width:200px"></el-input>
      </el-form-item>
      <el-form-item label="职位">
        <el-input v-model="dataInfo.title" autocomplete="off" placeholder="例如：技术总监 销售经理" style="width:200px"></el-input>
      </el-form-item>
      <el-form-item label="所属部门" prop="departId">
        <l-select v-model="dataInfo.departId" :all="false" :remote="$api.departList" size="medium"></l-select>
      </el-form-item>
      <el-form-item label="角色权限" prop="roleIds">
        <l-select v-model="dataInfo.roleIds_list" multiple collapse-tags :all="false" :remote="$api.roleList" size="medium"></l-select>
      </el-form-item>
      <el-form-item label="公司老板">
        <el-radio-group v-model="dataInfo.isBoss">
          <el-radio-button :label="true">是</el-radio-button>
          <el-radio-button :label="false">否</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="企业高管">
        <el-radio-group v-model="dataInfo.isSenior">
          <el-radio-button :label="true">是</el-radio-button>
          <el-radio-button :label="false">否</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="部门领导">
        <el-radio-group v-model="dataInfo.isLeader">
          <el-radio-button :label="true">是</el-radio-button>
          <el-radio-button :label="false">否</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="管理员">
        <el-radio-group v-model="dataInfo.isAdmin">
          <el-radio-button :label="true">是</el-radio-button>
          <el-radio-button :label="false">否</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注信息">
        <el-input type="textarea" rows="4" placeholder="请输入内容" v-model="dataInfo.remark"> </el-input>
      </el-form-item>
      <el-form-item label="用户头像"> <l-upload-img v-model="dataInfo.avatar"> </l-upload-img> </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import LUploadImg from '@/lgh/components/uploadimg'
export default {
  components: { LUploadImg },
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      departList: null,
      dataRules: {
        mobile: [{ required: true, trigger: 'blur', message: '手机号不能为空' }],
        name: [{ required: true, trigger: 'blur', message: '员工姓名不能为空' }],
        departId: [{ required: true, trigger: 'blur', message: '部门不能为空' }]
      }
    }
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    },
    save() {
      this.$refs.dataInfo.validate(async valid => {
        if (valid) {
          await this.$lgh.post('api/enterprise/employee/addoredit', this.dataInfo)
          this.visible = false
          this.onsuccess && this.onsuccess(this.dataInfo)
        }
      })
    }
  }
}
</script>

<style></style>
